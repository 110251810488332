<template>
  <div
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="top" v-if="isXian">
      <div class="top-select">
        <el-form :model="SerachInput" label-width="80px">
          <el-form-item label="日期范围">
            <el-col>
              <el-date-picker
                v-model="overallForm.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="日活统计">
            <el-input
              v-model="SerachInput.phones"
              placeholder="请输入内容"
              style="width: 150px"
              @keyup.enter.native="handleQuery"
            ></el-input>
          </el-form-item>
          <el-form-item label-width="26px">
            <img
              src="../../assets/images/query.png"
              alt=""
              style="width: 49px; margin-left: 5%"
              @click="handleQuery"
            />
          </el-form-item>
        </el-form>
      </div>
      <div class="table-content">
        <el-table
          :data="tableData"
          tooltip-effect="dark"
          ref="multipleTable"
          style="width: 100%"
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column prop="dateTime" label="日期" width="130">
          </el-table-column>
          <el-table-column
            prop="totalStartup"
            label="APP启动总次数"
            width="130"
          >
          </el-table-column>
          <el-table-column
            prop="totalLoginMsg"
            label="登录验证短信数量"
            width="160"
          >
          </el-table-column>
          <el-table-column prop="loginSuccess" label="登录成功次数" width="110">
          </el-table-column>
          <el-table-column prop="loginApple" label="苹果登录次数" width="110">
          </el-table-column>
          <el-table-column prop="loginAndroid" label="安卓登录次数" width="110">
          </el-table-column>
          <el-table-column prop="totalAdduser" label="新增用户总数" width="110">
          </el-table-column>
          <el-table-column
            prop="adduserApple"
            label="苹果新增"
            width="110"
          ></el-table-column>
          <el-table-column
            prop="adduserAndroid"
            label="安卓新增"
            width="110"
          ></el-table-column>
        </el-table>
      </div>
      <div class="bottom">
        <div class="bottom-left">
          <p class="bottom-shu">共计{{ totalCount }}条数据</p>
        </div>
        <div class="bottom-right">
          <div class="bottom-fen">
            <!-- 分页 -->
            <div class="pages">
              <el-pagination
                @current-change="handleCurrentChange"
                :page-size="pageSize"
                :current-page="pageCount"
                layout="total, prev, pager, next"
                :total="totalCount"
                @pagination="getEquipment"
              >
              </el-pagination>
              <!-- <el-pagination layout="prev, pager, next" :total="50">
              </el-pagination> -->
            </div>
          </div>
          <div class="buttons">
            <el-button>数据分析</el-button>
            <el-button>数据导出</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 增加的弹框 -->
    <div>
      <el-dialog title="提示" :visible.sync="dialogVisible" width="50%">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="选择状态">
            <el-select v-model="formInline.useState" placeholder="请选择">
              <el-option
                v-for="item in optionStatus"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="preservation">保 存</el-button>
        </span>
      </el-dialog>
    </div>
    <div>
      <el-dialog
        title="查看图片"
        :visible.sync="centerDialogVisible"
        width="30%"
        center
      >
        <span
          ><img style="width: 100%; margin: 0 auto" :src="photoUrls"
        /></span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import { api } from "../../api1/config";
import { apiShout } from "../../api1/configShout";
export default {
  inject: ["reload"],
  data() {
    return {
      clientHeight: document.body.clientHeight,
      //增加的弹框
      dialogVisible: false,
      centerDialogVisible: false,
      isXian: true,
      photoUrls: "",
      unitName: [],
      //性别下拉
      optionssex: [],
      optionssexs: [],
      // optionssexsType:[],
      optionStatus: [],
      options: [
        {
          value: "1",
          label: "全部",
        },
        {
          value: "2",
          label: "条目编号",
        },
        {
          value: "3",
          label: "分类",
        },
      ],
      overallForm: {
        time: [],
      },
      SerachInput: {
        tiem: null,
        tiems: null,
        type: "",
        phones: "",
        regionTwo: "",
      },
      tiem: "",
      tiems: "",
      value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
      value: "",
      input: "",
      title: "",
      tableData: [],
      //分页
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      pageCount: 1, //网络请求的页码
      unitNamePage: 0,
      keyIds: [],
      regionsId: null,
      regionsIds: null,
      //增加的表单
      formInline: {
        id: "",
        createDate: null,
        updateDate: null,
      },
    };
  },
  created() {
    this.getEquipment();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    handleQuery() {
      this.getEquipment();
    },
    getEquipment() {
      apiShout
        .getsticss({
          pageCount: this.pageCount,
          pageSize: this.pageSize,
          startTime:
            this.overallForm.time == null ? "" : this.overallForm.time[0],
          endTime:
            this.overallForm.time == null ? "" : this.overallForm.time[1],
          startCount: this.SerachInput.phones,
        })
        .then((res) => {
          console.log(res, "查询统计数");
          this.tableData = res.data.result.data;
          this.totalCount = res.data.result.page.totalCount;
        });
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      this.pageCount = pageCount;
      this.getEquipment();
    },
    // 取消
    cancel() {
      this.dialogVisible = false;
      this.formInline = {};
    },
    //保存
    preservation() {},
  },
};
</script>

<style lang='less' scoped>
/deep/.el-form-item__label {
  color: #909399;
  text-align: left;
}
/deep/.el-input__inner {
  height: 33px;
  line-height: 33px;
  padding: 0 10px;
}
/deep/.el-form-item {
  margin-bottom: 0px;
  margin-right: 30px;
}
.el-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.top {
  height: 200px;
  margin: 20px 40px 20px 25px;
}
.top-btn {
  float: left;
  margin-right: 30px;
  background: #d78673;
  color: #ffffff;
}

.top-search {
  position: relative;
}
.con-size {
  margin: 0px 10px 20px 40px;
}
.top-img {
  margin-left: 10px;
  position: absolute;
  top: 60px;
}
/deep/.el-table::before {
  height: 0px;
}
.table-content {
  padding: 30px 10px 10px 0px;
}
/deep/ .el-table .cell {
  text-align: center;
}
.bottom-right {
  float: right;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  .el-button {
    width: 130px;
    height: 40px;
    line-height: 40px;
    border-radius: 25px;
    margin-top: 20px;
    margin-right: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 16px;
    padding: 0px;
  }
}
.bottom-b {
  float: right;
  margin-right: 10%;
}
.top-btn1 {
  height: 20px;
  line-height: 0;
  font-size: 16px;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  float: left;
  margin-right: 20px;
  background: #d78673;
  color: #ffffff;
}
.bottom-fen {
  margin-top: 30px;
}
.bottom-left {
  position: relative;
}
.bottom-shu {
  font-size: 15px;
  color: #606266;
}
.healLabel {
  width: 400px;
  height: 50px;
  background-color: #ebe9e9;
  line-height: 50px;
  float: left;
  margin-right: 20px;
  overflow: hidden;
  letter-spacing: 1px;
}
.offen {
  margin-left: 150px;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
</style>